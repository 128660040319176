
import { provide, ref, unref, toRef, computed, watch, onMounted, nextTick } from 'vue'
import { getCookie } from '@shein/common-function'

import { useAttrForAnalysis } from './useAttrForAnalysis.js'
import { useAddToBag } from './useAddToBag.js'
import { usePrices } from './usePrices.js'

import { controlItemLabels, controlBeltPrice, reportMetricCount } from '../utils/index.js'
import { getProductDetailUrl } from 'public/src/pages/common/utils/index.js'
import { useDiscount } from './useDiscount.js'

// 商卡公共props
export const cardProps =  {
  /**
   * config 配置展示选项
   * 
   * 埋点配置
   * @param {String} daEventClickId 点击埋点id，不传默认2-3-1
   * @param {Boolean} useOwnClickExposeAnalysis 是否使用自己的点击，曝光埋点
   * @param {Boolean} useOwnClickAddBagAnalysis 是否使用自己的点击，加车埋点
   * @param {String} code ga 埋点需要
   * @param {String} from sa的activity_from
   * 
   * 隐藏配置
   * @param {Boolean} hideOperationBtn 操作按钮 add to bag, find similar
   * @param {Boolean} hidePromotionIcon 隐藏促销活动图标
   * @param {Boolean} hidePromotionLabel 隐藏促销活动标签
   * @param {Boolean} hideNormalPrices 隐藏普通价格（售卖价，零售价）
   * @param {Boolean} hideDiscountBadge 隐藏左上角折扣标签 TODO：闪购、专题旧逻辑
   * @param {Boolean} noNeedAnyEstimatedPriceTag 隐藏价格下面的到手价描述标签
   * 
   * 展示配置
   * @param {Boolean} isCamelCasePrice 在售价格字体是否驼峰
   * @param {Boolean} showGoodsNameTwoLine 商品名称展示两行
   * @param {Boolean} showGoodsNameLabel 是否展示商品名称前标签
   * @param {Boolean} showBadge 角标 new，top rate，low in stock goodsItemInfo里面控制数据的处理
   * @param {Boolean} showMainImgColor 展示主图色块并且关闭色块切换功能
   * @param {Boolean} showMultiColor 色块(控制是否展示色块,包括 可切换图片的色块 & 主图右下角的色块)
   * @param {Boolean} showFindSimilar find similar按钮
   * @param {Boolean} showAddBoard add to board按钮
   * @param {Boolean} showAddBagBtn 加车按钮
   * @param {Boolean} showLeftStock 剩余库存（0-10 不包含首尾） romwe
   * @param {Boolean} showLeftStockShein 剩余库存（0-10 不包含首尾） shein
   * @param {Boolean} showSellingPoint 卖点角标
   * @param {Boolean} showLocalSeller 本地发货标签
   * @param {Boolean} showQuickShip quickship标签
   * @param {Boolean} showSpuImg spu图
   * @param {Boolean} showSheinClubPriceOnSale 付费会员价，占用原来的销售价
   * @param {Boolean} showSheinClubNewLabel 付费会员新标签
   * @param {Boolean} showNewFlashNewLabel 新型闪购新标签
   * @param {Boolean} showPriceDiscountDesc 价格优势说明标签
   * @param {Boolean} showSalesLabel 是否展示销量标签
   * @param {'priceBottom' | 'priceRight'} PriceV2LabelPosition 价格标签文案位置 priceBottom默认价格底部 priceRight价格右边
   * 
   * 
   * 以下两个跟新型闪购价格相关的配置，根据不同场景，一般来说配置其中一项即可，都配置会相互冲突
   * @param {Boolean} showNewFlashPrice 新型闪购价
   * @param {Boolean} showNewFlashPriceOnSale 新型闪购价，占用原来的销售价
   * @param {Boolean} showDiscountLabelAfterPrice 价格后面展示折扣标签
   * @param {Boolean} showUserBehaviorLabel 用户行为标签
   * @param {Boolean} showBeltLabel 腰带
   * @param {Boolean} showRankingList 榜单
   * @param {Boolean} showDecisionAttr 决策属性
   * @param {Boolean} hideDiscountLabel 超出一行展示的时候隐藏超出的标签（原始需求：隐藏折扣标签）
   * @param {Boolean} showSellAttr 销售属性展示。（收藏）
   * @param {String} showGoodsSpace 商品下面内容标签间距ABT
   * @param {Boolean} addRankingLabelToSwiper 是否将榜单标签添加到轮播图中 
   * @param {Boolean} showHorseRaceLabel 是否展示赛马标签 
   * @param {Boolean} showPropertyUpsell 属性类卖点标签
   * @param {Boolean} showDiscountLabelBeforeGoodsName 商品名称前展示折扣标签
   * @param {Boolean} showDiscountLabelBeforeGoodsNameHighPriority 商品名称前展示折扣标签，优先级最高
   * 
   * 合规价相关配置
   * @param {Boolean} hideSpecialDeDiscountLabel 命中德国合规价，商卡内折扣标签强制改为展示，外部场景强制隐藏折扣标签
   * 
   * 到手价新样式wiki:pageId=1460905528
   * @param {Boolean} showNewStyleEstimated 命中实验，针对ab价，样式不变，文案发生变化。 针对到手价&&会员到手价 售价位置不展示到手价，底部一个新组件承载到手价信息&&新的到手价文案
   * 
   * 样式相关配置
   * @param {Object} style 
   *    @param {String} salePriceFontSize 售卖价字号, 例子：12px
   *    @param {String} salePriceColor 售卖价颜色, 例子：#FF4C3A
   *    @param {String} discountLabel 左上角促销折扣角标样式，默认normal。其他选项 flashSale  TODO：闪购、专题旧逻辑
   *    @param {Object} operationBtn 操作按钮样式，例子：{ margin: 0 13px; }
   *    @param {Object} priceCtn 价格容器 例子：{ height: '24px', lineHeight: '24px' }
   *    @param {String} checkedBoxStyle: "black" | "origin";  //  check-box选择控件颜色样式  默认黑色black  橙色origin   
   *    @param {String} checkedBoxShape: "round" | "square";  //  check-box选择控件形状样式  默认圆形round  方形origin   
   *    @param {Object} addBagBtnStyle: 'default' | 'AddText';   //  加车按钮样式  default默认通用   AddText 显示Add文字
   * 
   * 其他配置
   * @param {Boolean} clickItemStopPropagation 点击商品图阻止冒泡
   * @param {Boolean} disableMainimgJump 禁止点击主图和标题跳转商详, 透传事件clickItem
   * @param {Boolean} disableMultiColorJump 禁止多色块跳转，透传事件clickMultiColor
   * @param {Boolean} useHoverEffect 是否开启鼠标悬浮效果
   * @param {Object} imgDataExp 图片裁剪的扩展信息 eg: { min: 1, max: 2, unCut: 1, cutTo: 600 }。经过计算后的裁剪尺寸小于min，则使用min；经过计算后的裁剪尺寸大于max，则使用max；unCut为1时，不裁剪; cutTo: 裁剪到xxx尺寸
   * @param {Object} commonParams 一些跟当前业务环境相关的公共参数
   *    @param {String} pageName // 页面名称
   * 
   * */
  index: {
    type: Number,
    required: true,
    default: 0,
  },
  item: {
    type: Object,
    required: true,
    default() {
      return {}
    },
  },
  adaLevel: {
    type: Number,
    default: 0,
  },
  // item实例
  goodsItemInfo: {
    type: Object,
    default: () => ({}),
  },
  // 进入可选中模式。隐藏操作按钮，收藏数和删除icon。修改a标签href，解除鼠标右键功能
  showSelect: {
    type: Boolean,
    default: false,
  },
  // checkbox显示位置
  // imgLeft 主图左上角
  // imgRight 主图右上角
  // mainLeft 商卡左边单独显示checkbox  只有单列商卡
  selectPosition: {
    type: String,
    default: 'imgRight',
  },
  // 选中状态
  selectStatus: {
    type: Boolean,
    default: false,
  },
  // 禁止选择
  selectDisable: {
    type: Boolean,
    default: false
  },
  // 是否聚焦选中范围
  focusSelectRange: {
    type: Boolean,
    default: false
  },
  // 展示找相似弹窗，需禁用操作按钮
  showSimilarPopup: {
    type: Boolean,
    default: false,
  },
  // 控制loading
  loading: {
    type: Boolean,
    default: false,
  },
  language: {
    type: Object,
    default: () => ({}),
  },
  // LAZY_IMG, LAZY_IMG_SOLID_COLOR, LAZY_IMG_SQUARE, GB_cssRight, IS_RW
  constantData: {
    type: Object,
    default: () => ({}),
  },
  bottomInfoStyle: {
    type: Object,
    default: () => ({ minHeight: '98px' }),
  },
  // 窗口打开类型
  openTarget: {
    type: String,
    default: '_self',
  },
  // 商品设计稿宽度
  productDesignWidth: {
    type: [String, Number],
    default: 0,
  },
  // @example '1-1'
  cropRate: {
    type: String,
    default: '3-4',
  },
  // 不使用图片懒加载
  noImgLazy: {
    type: Boolean,
    default: false,
  },
  // 开启商卡调试工具
  enableDevTool: {
    type: Boolean,
    default: true,
  },
  reportMetrics: {
    type: Object,
    default: () => ({})
  },
}

// 公共emit事件
export const cardEmit = [
  'addboard',
  'clickAddToBag',
  'clickMultiColor',
  'relateColorHover',
  'addWishSucc',
  'addWishAnalysis',
  'deleteWishAnalysis',
  'deleteItem',
  'clickItem',
  'select',
  'openQuickAdd',
  'cancelWish'
]

export const useCardMain = ({
  emit,
  props,
  labelsFromKey = 'pcStandardView'
}) => {

  const { constantData, config, language, index, adaLevel, bottomInfoStyle, openTarget, productDesignWidth, selectPosition, focusSelectRange, noImgLazy, reportMetrics } = props

  // 响应式props
  const item = toRef(props, 'item')
  const goodsItemInfo = toRef(props, 'goodsItemInfo')
  const selectStatus = toRef(props, 'selectStatus')
  const selectDisable = toRef(props, 'selectDisable')
  const showSelect = toRef(props, 'showSelect')
  const loading = toRef(props, 'loading')
  const showSimilarPopup = toRef(props, 'showSimilarPopup')
  

  // 常量 配置
  const { IS_RW, LAZY_IMG_SOLID_COLOR, GB_cssRight, PUBLIC_CDN } = constantData

  // 是否开启调试
  const supportVueDebug = typeof window === 'undefined' ? false : Boolean(getCookie('vueDebug'))

  let imgRatio = ''

  // ref dom
  const imgContainer = ref(null)
  const itemOperation = ref(null)
  const nameContainer = ref(null)

  const sizeInfo = ref(false)

  // hover回调 - S
  const hovering = ref(false)
  const handleMouseenter = () => {
    hovering.value = true
  }
  const handleMouseleave = () => {
    hovering.value = false
  } 
  // hover回调 - E

  const relateColorInfo = ref(null)
  const goodsInfo = computed(() => {
    const getInfoLoading = relateColorInfo.value?.pretreatInfo?.getInfoLoading
    return relateColorInfo.value && getInfoLoading === false
      ? relateColorInfo.value
      : item.value
  })

  // 业务数据 预处理对象
  const pretreatInfo = computed(() => {
    return goodsInfo.value.pretreatInfo || {}
  })

  // 主图信息角标 start
  const locateLabels = computed(() => {
    return goodsInfo.value[labelsFromKey]?.locateLabels || {}
  })

  const locateLabelsCtrl = computed(() => {
    const flashSale = pretreatInfo.value?.flashsale
    
    return controlItemLabels(locateLabels.value, {
      flashSale,
      config,
      discountInfo
    })
  })
  // 主图信息角标 end

  // 图片相关 start
  const spuImg  = computed(() => {
    return ((goodsInfo.value?.spu_image || [])[0]?.medium_image) || ''
  })
  const _showSpuImg  = computed(() => {
    return !!(config.showSpuImg && spuImg.value && spuImg.value !== 'filteredSpuImg')
  })
  const mainImg  = computed(() => {
    return _showSpuImg.value ? spuImg.value : goodsInfo.value?.goods_img || ''
  })
  // 图片相关 end

  // 售罄
  const saleout = computed(() => {
    return pretreatInfo.value?.saleout || { show: false }
  })

  const saleoutShow = computed(() => {
    return saleout.value.show
  })

  const beltPriceCtrl = computed(() => {
    return controlBeltPrice(config, {
      isSoldOut: saleout.value.show,
      pretreatInfo: pretreatInfo.value,
      labelsFromKey
    })
  })

  // 商品名称
  const goodsName = computed(() => {
    return goodsInfo.value?.goods_name || ''
  })


  const getInfoLoading = computed(() => {
    if (relateColorInfo.value) {
      const getInfoLoding = relateColorInfo.value?.pretreatInfo?.getInfoLoading === undefined
      return !!getInfoLoding
    }
    return false
  })

  // 主图加载中
  const showLoading = computed(() => {
    return (
      getInfoLoading.value || loading.value || addBagLoading.value
    )
  })

  // 禁用点击跳转
  const forbidJumpDetail = computed(() => {
    return showSelect.value || config.disableMainimgJump
  })

  const goodsDetailUrl = computed(() => {
    const jumpUrl = goodsInfo.value?.detail_url
    return jumpUrl || 'javascript:void(0)'
  })

  // 是否删除按钮显示
  const delBtnVisible = computed(() => {
    return saleoutShow.value && config.showDelete
  })

  const getImgRatio = async () => {

    const secondImgSrc = imgContainer.value?.$el?.querySelectorAll('.crop-image-container__img')
    let imgSrc = mainImg.value
    // 使用第二张图获取比例，因为hover的时候始终展示的第二张图
    if (secondImgSrc) {
      const secondImgSrcDom = Array.from(secondImgSrc)?.[1]
      if (secondImgSrcDom?.getAttribute('loaded')) imgSrc = secondImgSrcDom.getAttribute('src')
    }

    const goodsDetailData = {
      productDetailUrl: goodsDetailUrl.value,
      imgSrc,
      getUrlWithImgRatio: true
    }

    const res = await getProductDetailUrl(goodsDetailData)

    imgRatio = res.imgRatioValue

    return res.url
  }

  // 价格相关
  const {
    salePrice,
    retailPrice,
    estimatedPriceInfo,
    estimatedInfo,
    sheinClubPriceWithSymbolVal,
    exclusivePriceWithSymbolVal,
    specialData
  } = usePrices({ 
    goodsInfo, 
    sizeInfo, 
    config, 
    language 
  })

  // 折扣相关
  const discountInfo = useDiscount({ 
    config,
    specialData,
    goodsInfo,
    estimatedInfo,
    sheinClub: {
      ...(goodsInfo.value?.sheinClubPromotionInfo || {}),
      sheinClubPriceWithSymbolVal
    },
    exclusive: {
      ...(goodsInfo.value?.exclusivePromotionInfo || {}),
      exclusivePriceWithSymbolVal
    }
  })

  // 店铺链接 相关 start
  const storeInfo = computed(() => {
    return goodsInfo.value?.storeInfo
  })

  const showStoreBar = computed(() => {
    return goodsInfo.value?.storeInfo?.storeBusinessType === 2 && config?.showStoreBar
  })
  // 店铺链接 相关 end

  const deliveryInfo = computed(() => {
    return goodsInfo.value?.deliveryInfo || {}
  })

  const showDeliveryWords = computed(() => {
    let visible = false
    if (config?.showDeliveryWords) {
      if (deliveryInfo.value?.display_title) {
        visible = true
      }  else if (storeInfo.value?.isModStore && storeInfo.value.title) {
        visible = true
      } else if (storeInfo.value?.isChoiceStore && storeInfo.value.title) {
        visible = true
      }
    }
    return visible
  })

  // 埋点相关
  const { 
    setElAttr,
    setAttrForAnalysis,
    customizeElAttr,
  } = useAttrForAnalysis({
    imgContainerRef: imgContainer,
    nameContainerRef: nameContainer,
    index,
    item,
    config,
    goodsInfo,
    pretreatInfo,
    locateLabels,
    locateLabelsCtrl,
    spuImg,
    _showSpuImg,
    saleout,
    estimatedPriceInfo,
    beltPriceCtrl,
    goodsName,
    labelsFromKey
  })

  watch(
    goodsInfo,
    (val, oldVal) => {
      if (Number(val.goods_id) !== Number(oldVal.goods_id)) {
        setElAttr()
        nextTick(() => {
          window.dispatchEvent(new Event('scroll'))
        })
      }
    }
  )

  // 加车相关 start
  const { 
    addBagLoading,
    showAddBagBtn,
    handelOpenQuickAdd
  } = useAddToBag({
    emit,
    imgContainer,
    config,
    index,
    goodsInfo,
    imgRatio,
    saleout,
    getImgRatio,
    reportMetrics
  })
  // 加车相关 end

  // 图片右下角收藏按钮 找相似
  const showOperation = computed(() => {
    const configHideOperationBtn = config.hideOperationBtn
    return ((hovering.value && !showLoading.value && !forbidJumpDetail.value && !configHideOperationBtn) || (saleoutShow.value && !configHideOperationBtn))
      && !showSelect.value 
      && !showSimilarPopup.value
  })

  // 跳转
  const jumpDeatils = async () => {

    const isSafari = navigator.userAgent.indexOf('Safari') > -1 && navigator.userAgent.indexOf('Chrome') < 0

    if (openTarget === '_blank') {
      if (isSafari) {
        // 兼容 Safari 浏览器
        // 为了避免打开窗口被 Safari 拦截，先打开一个空白窗口，再赋值
        const newWindow = window.open(goodsDetailUrl.value, '_blank')
        const url = await getImgRatio()
        newWindow.location.href = url
      } else {
        const url = await getImgRatio()
        window.open(url)
      }
    } else {
      const url = await getImgRatio()
      window.location.href = url
    }
  }

  // 主图 or 标题 点击
  const handleClickItem = async ({ event, target }, options) => {
    
    // 阻止默认的 href 链接跳转，追加 imgRatio 参数
    event.preventDefault()

    if (config.clickItemStopPropagation) event.stopPropagation()

    const checkboxTarget = event.target?.closest('.sui-checkbox__label-select')

    if (focusSelectRange && checkboxTarget) {
      return
    }

    let clickPosition = options?.from ?? ''

    emit('clickItem', { ...goodsInfo.value, target, index, imgRatio, clickPosition })
    
    // 如果商品项目 href 是 javascript:void(0) 则不跳转
    if (goodsDetailUrl.value?.includes('javascript:void(0)') || forbidJumpDetail.value) {
      return
    }

    // 跳转商详的监控指标
    reportJumpDetailMetric()
    
    jumpDeatils()
  }

  // 上报跳转商详的监控指标
  const reportJumpDetailMetric = () => {
    const pageName = config?.commonParams?.pageName
    if (!reportMetrics?.jumpDetail || !pageName) return

    reportMetricCount({
      metricName: 'click_to_detail_total',
      tags: { page: pageName },
      message: 'Number of jump detail'
    })
  }

  const handleSelect = (event) => {
    if (selectDisable.value) return

    const checkboxTarget = event.target?.closest('.sui-checkbox__label-select')

    if (focusSelectRange && !checkboxTarget) {
      return
    }

    emit('select', { item: unref(goodsInfo), selected: !unref(selectStatus) })
  }

  onMounted(() => {
    setElAttr()
  })

  provide('labelsFromKey', labelsFromKey)
  provide('item', item)
  provide('config', config)
  provide('language', language)
  provide('constantData', constantData)
  provide('index', index)
  provide('setAttrForAnalysis', setAttrForAnalysis)
  provide('supportVueDebug', supportVueDebug)
  provide('specialData', specialData)

  return {
    supportVueDebug,
    
    // data
    hovering,
    sizeInfo,
    relateColorInfo,
    goodsDetailUrl,

    // static config
    IS_RW,
    GB_cssRight, 
    PUBLIC_CDN,
    LAZY_IMG_SOLID_COLOR, 

    // props
    item, 
    config, 
    language, 
    showSelect, 
    selectPosition,
    selectDisable,
    selectStatus, 
    index, 
    adaLevel, 
    goodsItemInfo, 
    showSimilarPopup, 
    bottomInfoStyle, 
    openTarget, 
    productDesignWidth,
    noImgLazy,
    reportMetrics,

    // dom ref
    imgContainer,
    itemOperation,
    nameContainer,

    pretreatInfo,
    goodsInfo,
    discountInfo,

    locateLabels,
    locateLabelsCtrl,

    spuImg,
    _showSpuImg,

    saleout,
    saleoutShow,
    beltPriceCtrl,
    goodsName,
    delBtnVisible,
    showLoading,

    forbidJumpDetail,
    estimatedPriceInfo,
    salePrice,
    retailPrice,
    estimatedInfo,

    storeInfo,
    showStoreBar,

    deliveryInfo,
    showDeliveryWords,

    showOperation,
    showAddBagBtn,
    handelOpenQuickAdd,

    handleClickItem,
    handleSelect,

    handleMouseenter,
    handleMouseleave,

    setElAttr,
    setAttrForAnalysis,
    customizeElAttr,
  }
}
