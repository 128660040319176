import { computed } from 'vue'

import { isDeSuggestedSalePrice } from '../utils/priceDisplayTheResults'

export const useDiscount = ({ config = {}, specialData, goodsInfo, estimatedInfo, sheinClub, exclusive }) => {
  const { pretreatInfo, discountPrice } = goodsInfo.value || {}

  const { showNewStyleEstimated } = config ?? {}
  
  const { discountInfo, suggestedSalePriceConfigInfo } = pretreatInfo || {}
  const { suggestedSalePriceType, unitDiscountString: normalUnitDiscountString, retailDiscountPrice, retailDiscountPercentString } = discountInfo || {}

  const { estimatedDiscount, estimatedDiscountPrice, estimatedPriceType, showEstimatedPrice } = estimatedInfo.value || {}

  const { discountPercentString, sheinClubPriceWithSymbolVal, discount_amount, retail_discount_price } = sheinClub || {}

  const { 
    exclusivePriceWithSymbolVal,
    discountPercentString: exclusiveDiscountPercentString, 
    discount_amount: exclusiveDiscount_amount, 
    retail_discount_price: exclusiveRetail_discount_price
  } = exclusive || {}

  // 是否为德国合规价
  const isSuggestedSpecialDe = isDeSuggestedSalePrice(suggestedSalePriceConfigInfo?.type)

  const getDiscountInfo = () => {
    const discountInfo = {
      suggestedSalePriceType,
      show: false,
    }

    const setDiscountPrice = (discountPrice) => ({ discountPrice: discountPrice || '' })
    const setUnitDiscountString = (unitDiscountString, color = 'origin') => ({ unitDiscountString: unitDiscountString || '', show: true, color })

    const getValue = ({ discountPrice, unitDiscountString, color }) => {
      return {
        ...setDiscountPrice(discountPrice),
        ...setUnitDiscountString(unitDiscountString, color)
      }
    }

    let value = {}
    if (estimatedDiscount && showEstimatedPrice && !showNewStyleEstimated) { // 到手价
      // 付费会员与s3会员不同颜色
      const color = {
        1: 'origin',
        2: 'brown',
        3: 'origin',
        4: 'darkGold',
      }
      value = getValue({ discountPrice: estimatedDiscountPrice?.amountWithSymbol, unitDiscountString: estimatedDiscount, color: color[estimatedPriceType]  })

      // s3到手价 不展示折扣标签
      if (estimatedPriceType === 4) {
        value.show = false
      }
    } else if (sheinClubPriceWithSymbolVal && isSuggestedSpecialDe && specialData?.showSusaPrice && sheinClub.retailDiscountPercentString) { // 付费会员 德国合规场景
      value = getValue({ discountPrice: retail_discount_price?.amountWithSymbol, unitDiscountString: sheinClub.retailDiscountPercentString, color: 'brown' })

    } else if (sheinClubPriceWithSymbolVal) { // 付费会员 普通场景
      value = getValue({ discountPrice: discount_amount?.amountWithSymbol, unitDiscountString: discountPercentString, color: 'brown' })

    } else if (exclusivePriceWithSymbolVal && isSuggestedSpecialDe && specialData?.showSusaPrice && exclusive.retailDiscountPercentString) { // s3会员 德国合规场景
      value = getValue({ discountPrice: exclusiveRetail_discount_price?.amountWithSymbol, unitDiscountString: exclusive.retailDiscountPercentString, color: 'darkGold' })

    } else if (exclusivePriceWithSymbolVal) { // s3会员 普通场景 隐藏折扣标签
      value = getValue({ discountPrice: exclusiveDiscount_amount?.amountWithSymbol, unitDiscountString: exclusiveDiscountPercentString, color: 'darkGold' })
      value.show = false
    } else if (isSuggestedSpecialDe && specialData?.showSusaPrice && pretreatInfo?.discountInfo?.retailDiscountPercent) { // 普通折扣和新型闪购 德国合规场景
      value = getValue({ discountPrice: retailDiscountPrice?.amountWithSymbol, unitDiscountString: retailDiscountPercentString })

    } else if (normalUnitDiscountString) { // 普通折扣和新型闪购 普通场景
      value = getValue({ discountPrice: discountPrice?.amountWithSymbol, unitDiscountString: normalUnitDiscountString })
    }

    if (!value) return

    return Object.assign(discountInfo, value)
  }

  // 折扣信息
  const finalDiscountInfo = computed(() => {
    return getDiscountInfo({ discountInfo })  // 折扣比例和可省金额设值
  })

  return finalDiscountInfo
}
