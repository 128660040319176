<template>
  <!-- 价格右侧 区域 -->
  <!-- 折扣标签 -->
  <DiscountLabel 
    v-if="showDiscountValue.show"
    :type="showNewDiscountLabel && !isRw ? 'newDiscount' : 'discount'"
    :discount-info="showDiscountValue"
  />

  <!-- 销量标签 -->
  <div 
    v-if="salesLabels && salesLabels.labelLang"
    :style="salesLabelsStyle"
    class="product-card__sales-label"
    :class="{
      'sales-label-hot': salesLabels.icon
    }"
  >
    <img 
      v-if="salesLabels.icon" 
      class="sales-label__icon"
      :src="salesLabels.icon" 
    />
    {{ salesLabels.labelLang }}
  </div>
</template>

<script name="ProductCardPriceRight" setup>
import DiscountLabel from '../common/DiscountLabel.vue'
import { isDeSuggestedSalePrice } from '../../utils/priceDisplayTheResults'

import { inject, toRefs, computed } from 'vue'
import { labelTypeMap } from '../../utils/constant.js'

const labelsFromKey = inject('labelsFromKey')
const config = inject('config', () => { return { } })
const constantData = inject('constantData', () => { return { } })

const setAttrForAnalysis = inject('setAttrForAnalysis')

const isRw = constantData.IS_RW

const props = defineProps({
  discountInfo: {
    type: Object,
    default: () => ({}),
  },
  goodsInfo: {
    type: Object,
    default: () => ({}),
  },
})

const { discountInfo, goodsInfo } = toRefs(props)

const { showDiscountLabelAfterPrice, showNewDiscountLabel } = config

const hideDiscount = computed(() => {
  return goodsInfo.value?.pretreatInfo?.suggestedSalePriceConfigInfo?.config?.hideDiscount
})

const isDeSuggestedSalePriceType = isDeSuggestedSalePrice(discountInfo.value?.suggestedSalePriceType)

// 折扣标签信息
const showDiscountValue = computed(() => {
  const defaultValue = {
    show: false
  }

  if (showDiscountLabelAfterPrice && !hideDiscount.value && !isDeSuggestedSalePriceType) return discountInfo.value
  
  return defaultValue
})

// 销量标签
const salesLabels = computed(() => {
  const hasOwnPropertyShowSalesLabel = config.hasOwnProperty('showSalesLabel')
  if (!hasOwnPropertyShowSalesLabel || (hasOwnPropertyShowSalesLabel && config.showSalesLabel !== false)) {
    return goodsInfo.value?.[labelsFromKey]?.salesLabels ?? {}
  } else {
    return {}
  }
})

const salesLabelsStyle = computed(() => {
  return {
    color: salesLabels.value?.fontColor || '', 
    backgroundColor: salesLabels.value?.backgroundColor || ''
  }
})

if (salesLabels.value?.labelLang) {
  setAttrForAnalysis((el) => {
    const anaValue = `show_sales_label_${labelTypeMap.get(salesLabels.value.textType)}_${salesLabels.value.labelId}_${salesLabels.value.labelLang?.replace(/\s+/g, '_')}`
    el.setAttribute('data-sales-label', salesLabels.value?.appTraceInfo || anaValue)
  })
}

</script>

<style lang="less" scoped>
.product-card__sales-label {
  .flexbox();
  .align-center();
  
  font-size: 14px;
  color: var(--sui-color-gray-dark-3, #767676);
  .sales-label {
    &__icon {
      width: 14px;
      height: 14px;
      margin-right: 2px;
    }
  }
}
</style>
