<template>
  <div 
    v-if="unitDiscountString"
    class="product-card__discount-label notranslate"
    :class="[
      colorClassName,
      {
        'discount-label_default': props.type === 'default',
        'discount-label_promo': props.type === 'promo',
        'discount-label_flash': props.type === 'flash',
        'discount-label_discount': props.type === 'discount' || props.type === 'newDiscount',
        'discount-label_discount-new': props.type === 'newDiscount',
      }
    ]"
  >
    {{ unitDiscountString }}
  </div>
</template>

<script name="DiscountLabel" setup>
import { inject, toRef, computed } from 'vue'

const constantData = inject('constantData')

const props = defineProps({
  type: {
    type: String,
    default: 'default'
  },
  discountInfo: {
    type: Object,
    default: () => ({})
  },
  // 是否ar站翻转  -50% 需要转成  %50-
  cssRight: {
    type: Boolean,
    default: false
  }
})

const discountInfo = toRef(props, 'discountInfo')

const unitDiscountString = computed(() => {
  const cssRight = constantData?.GB_cssRight
  let unitDiscountString = discountInfo.value?.unitDiscountString

  if (!unitDiscountString) return ''

  if (cssRight || props?.cssRight) {
    unitDiscountString = unitDiscountString.replace(/(-?)(\d+(\.\d+)?)(%)/, '$4$2$1')
  }

  return unitDiscountString
})

const colorClassName = computed(()=> {
  return discountInfo.value?.color
})


</script>

<style lang="less">
.product-card {
  &__discount-label {
    font-size: 12px;
    line-height: 22px;
    direction: ltr /*rtl:ignore*/;
    padding: 0 6px;
    &.discount-label {
      &_default {
        background: @sui_color_main;
        color: #fff;
      }
      &_promo {
        color: #fff;
        background: @sui_color_promo;
      }
      &_flash {
        background: @sui_color_flash;
        /*sh:begin*/
        color: #222;
        /*rw:begin*/
        color: #fff;
      }
      &_discount {
        height: 16px;
        line-height: 16px;
        padding: 0 2px 0 3px;
        border: 1px solid @sui_color_discount;
        color: @sui_color_discount;
        border-radius: 2px;
      }
      &_discount-new {
        color: #fff;
        background-color: @sui_color_discount;
      }
    }

    // 如果是会员相关的，折扣样式（字体颜色这些存在变化）
    &.brown {
      border-color: @sui_color_club_rosegold_dark1;
      color: @sui_color_club_rosegold_dark1; 
    }
    &.darkGold {
      border-color: #806208;
      color: #806208;
    }
  }
}
</style>
