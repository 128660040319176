<template>
  <!-- 价格说明新样式，只针对到手价，参见：pageId=1460905528 -->
  <template v-if="newStylePriceLabel">
    <div
      :class="[
        'product-card-price-v2-bottom-label', 
        'product-card-price-v2-bottom-label_new', 
        isSheinClubePrice && 'clube-price-intro',
        isExclusiveEstimatedPrice && 'exclusive-price-intro'
      ]"
    >
      <div class="price-intro_estimated">
        {{ estimatedInfo?.showEstimatedPrice }}
      </div>
      <span class="price-intro_line"></span>
      <p class="price-intro_text">
        {{ text }}
      </p>
    </div>
  </template>
  <template v-else>
    <p 
      class="product-card-price-v2-bottom-label"
      :class="[color]"
    >
      {{ text }}
    </p>
  </template>
</template>

<script name="ProductCardPriceV2BottomLabel" setup>
import { toRefs, computed } from 'vue'

const props = defineProps({
  text: {
    type: String,
    default: ''
  },
  color: {
    type: String,
    default: ''
  },
  showNewStyleEstimated: {
    type: Boolean,
    default: false
  },
  estimatedInfo: {
    type: Object,
    default: () => ({}),
  },

})


const { text, estimatedInfo, showNewStyleEstimated } = toRefs(props)

const newStylePriceLabel = computed(()=> {
  return showNewStyleEstimated.value && estimatedInfo.value.showEstimatedPrice
})
const isSheinClubePrice = computed(()=> {
  return estimatedInfo.value.estimatedPriceType === 2 // 是付费会员到手价
})

const isExclusiveEstimatedPrice = computed(()=> {
  return estimatedInfo.value.estimatedPriceType === 4 // 是s3会员到手价
})
</script>

<style lang="less" scoped>
/* stylelint-disable-next-line selector-class-pattern */
.product-card-price-v2-bottom-label {
  .line-camp(1);
  
  height: 14px;
  line-height: 14px;

  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;

  word-break: break-all;
  vertical-align: middle;
  
  font-size: 12px;

  // &.brown {
  //   color: @sui_color_club_rosegold_dark1;
  // }

  // &.origin {
  //   color: @sui_color_discount;
  // }
}
.brown {
  color: @sui_color_club_rosegold_dark1;
}

.origin {
  color: @sui_color_discount;
}

.darkGold {
  color: #806208
}


/* stylelint-disable-next-line selector-class-pattern */
.product-card-price-v2-bottom-label_new {
  .line-camp(1);
  display: flex;
  height: 18px;
  line-height: 18px;
  padding: 4px 2px;
  box-sizing: content-box;
  align-items: center;
  justify-self: center;
  border-radius: 2px;

  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  word-break: break-all;
  vertical-align: middle;
  
  font-size: 16px;
  background-color: var(--promo_BG, #FFF1ED);
  color: @sui_color_discount;
  .price-intro_estimated {
    color: var(--sui_color_discount, #FA6338);
    font-weight: 700;
    white-space: nowrap;
  }
  .price-intro_line {
    width: 1px;
    height: 10px;
    margin: 0 4px;
    background-color: @sui_color_discount;
  }
  .price-intro_text {
    line-height: 1.3em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 400;
  }
}
.clube-price-intro {
  background-color: var(--SheinClub_bg, #FFF5ED);
  color: @sui_color_club_rosegold_dark1;
  .price-intro_estimated {
    color: var(--sui_color_discount, @sui_color_club_rosegold_dark1);
  }
  .price-intro_line {
    background-color: @sui_color_club_rosegold_dark1;
  }
}

.exclusive-price-intro {
  background-color: #FFFBED;
  color: #806208;
  .price-intro_estimated {
    color: #806208;
  }
  .price-intro_line {
    background-color: #806208;
  }
}

// 处理ar站价格信息被反转问题
[mir=rtl] {
  .price-intro_estimated {
    direction: ltr;
  }
}

</style>
